import './App.css';
import Home from './pages/home';
import Mission from './pages/mission';
import Lab from './pages/lab';
import { useState } from 'react';

function App() {
  const [nowPage, setNowPage] = useState("home");

  function changePage(e) {
    const target = e.target.id;

    if (target === "home") {
      setNowPage("home");
      return;
    }
    if (target === "mission") {
      setNowPage("mission");
      return;
    }
    if (target === "lab") {
      setNowPage("lab");
      return;
    }
  }

  return (
    <>
      {nowPage === "home" ? (
        <Home nowPage={nowPage} changeFn={changePage} />
      ) : nowPage === "mission" ? (
        <Mission nowPage={nowPage} changeFn={changePage} />
      ) : (
        <Lab nowPage={nowPage} changeFn={changePage} />
      )}
    </>
  );
}

export default App;
