import { faInstagram, faWeixin, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import xian from "../assets/clilogo/xianhongshu.png"
import blog from "../assets/clilogo/blog.png"

export default function CopDesc() {

    return (
        <>
            <div className="cop-desc">
                <div className="cop-info">
                    <p>
                        <strong>(주)하티웍스</strong><br />
                        사업자등록번호 513-86-01246<br />
                        전화번호 02-6925-1203 <p>전자우편 vanessa@heartyworks.com</p><br />
                        주소 (법인) 서울특별시 성동구 뚝섬로 1나길 5, 6층<br />
                        주소 (사무국) 서울특별시 구로구 디지털로 300, 1232호
                    </p>
                </div>
                <div className="cop-sns">
                    <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/heartyworks_official/?igsh=c3RxN3BuOGd1ZHNl"><FontAwesomeIcon className="icon" icon={faInstagram}></FontAwesomeIcon></a>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/@HEARTYWORKS/"><FontAwesomeIcon style={{ padding: "0 0 0 14px" }} className="icon" icon={faYoutube}></FontAwesomeIcon></a>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.xiaohongshu.com/user/profile/5f4ca3b0000000000101df42?xhsshare=CopyLink&appuid=58df57e26a6a69513dbe7082&apptime=1707108837"><img style={{ width: "50px", height: "50px", padding: "0 0 0 6px" }} className="icon" src={xian} alt="xiaohongshu" /></a>
                    <a target="_blank" rel="noopener noreferrer" href="https://blog.naver.com/koreanatyourdoor"><img style={{ width: "45px", height: "45px" }} className="icon" src={blog} alt="naver blog" /></a>
                    <a target="_blank" rel="noopener noreferrer" href="https://mp.weixin.qq.com/s/97ybO2ao1_yBaU5rNCywCg?sessionid=1707108793&subscene=0&scene=126&clicktime=1707108935&enterid=1707108935"><FontAwesomeIcon style={{ padding: "0 0 0 10px" }} className="icon" icon={faWeixin}></FontAwesomeIcon></a>
                </div>
            </div>
        </>
    )
}