import React, { useState, useEffect } from 'react';
import logo from "../assets/files/asset1_small.png";

export default function TopNav({ nowPage, changeFn }) {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    useEffect(() => {
        if (menuOpen) {
            // 드래그 비활성화
            document.body.style.overflow = 'hidden';
        } else {
            // 드래그 활성화
            document.body.style.overflow = 'auto';
        }
    }, [menuOpen]);

    return (
        <div className='top-nav'>
            <div className='top-items'>
                <img id="home" onClick={changeFn} src={logo} alt="logo" />
                <div className={`nav-bar ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                {!menuOpen && (
                    <div className='right-nav'>
                        <div
                            onClick={changeFn}
                            id="home"
                            className={nowPage === "home" ? "active" : ""}
                        >
                            홈
                        </div>
                        <div
                            onClick={changeFn}
                            id="mission"
                            className={nowPage === "mission" ? "active" : ""}
                        >
                            우리의 미션
                        </div>
                        <div
                            onClick={changeFn}
                            id="lab"
                            className={nowPage === "lab" ? "active" : ""}
                        >
                            실험실
                        </div>
                    </div>
                )}
            </div>
            {menuOpen && (
                <div className="menu-content">
                    <div onClick={changeFn} id="home" className={nowPage === "home" ? "active" : ""}>
                        홈
                    </div>
                    <div onClick={changeFn} id="mission" className={nowPage === "mission" ? "active" : ""}>
                        우리의 미션
                    </div>
                    <div onClick={changeFn} id="lab" className={nowPage === "lab" ? "active" : ""}>
                        실험실
                    </div>
                </div>
            )}
        </div>
    );
}
