import React from 'react';
import PropTypes from 'prop-types';

LabItem.propTypes = {
    imgSrc: PropTypes.string.isRequired,
    altText: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    extraInfo: PropTypes.string,
};

export default function LabItem({ imgSrc, altText, title, description, extraInfo }) {
    const imgClassName = imgSrc.includes('노인 고립') ? 'lab-item-img fill' : 'lab-item-img';

    return (
        <div className="lab-item">
            <img className={imgClassName} src={imgSrc} alt={altText} />
            <div className="lab-text">
                <h3>{title}</h3>
                <p>{description}</p>
                {extraInfo && <p><em>{extraInfo}</em></p>}
            </div>
        </div>
    );
}
