import CopDesc from "../components/CopDesc";
import TopNav from "../components/TopNav";
import mission from "../assets/files/bn1.png"
import exImg1 from "../assets/files/스크린샷 2024-01-31 오후 12.00.02.png";
import exImg2 from "../assets/files/스크린샷 2024-01-31 오후 12.18.42.png";
import exImg3 from "../assets/files/라이프1.png";
import exImg4 from "../assets/files/스크린샷 2024-02-04 오후 11.48.03.png"

const features = [
    {
        image: exImg1,
        title: 'Not Common',
        subtitle: '우리는 특별합니다',
        description: `우리는 가능성에 집중합니다.\n장애인의 직업적 한계를 벗어나 잠재력\n을 발휘할 수 있도록 돕습니다.`
    },
    {
        image: exImg2,
        title: 'Not Just Kind',
        subtitle: '우리는 진정성있는 행동을 합니다',
        description: `우리는 진정성있는 발걸음을 내딛습니\n다.\n겉으로만 보여지는 것이 행위가 아닌 진\n실된 마음으로 행동합니다.`
    },
    {
        image: exImg3,
        title: 'Not Boring',
        subtitle: '우리는 유쾌합니다',
        description: `우리는 유쾌함을 잃지 않습니다.\n더 멋진 방식의 포용을 위하여 웃음을 잃지\n않고 나아갑니다.`
    }
];

export default function Mission({ nowPage, changeFn }) {

    return (
        <div className="mission-main">
            <TopNav nowPage={nowPage} changeFn={changeFn} />
            <img src={mission} alt="our mission" className="mission-page" />
            <div className="mission-cop-desc">
                <div className="items">
                    <h1>일터에서 소외된 사람들을 위한 기업</h1>
                    <p style={{ fontSize: "18px", marginTop: "20px", lineHeight: 1.5 }}>
                        하티웍스는 일터에서의 포용력 증진을 위해 존재합니다.<br />
                        특히 일터에서 소외된 사람들에게 교육을 통한 기회를 제공하며 일터에서의<br />
                        DEI(Diversity · Equity · Inclusion)의 가치 실현에 앞장서나가고 있습니다.<br />
                        하티웍스는 따뜻하고 유쾌한 일을 하는 사람들입니다.
                    </p>
                </div>
            </div>
            <div className="features-container">
                <div className="feature-items">
                    {features.map((feature, index) => (
                        <div key={index} className="feature-item">
                            <img src={feature.image} alt={feature.subtitle} className="feature-image" />
                            <h3>{feature.title}</h3>
                            <h2>{feature.subtitle}</h2>
                            <p>{feature.description}</p>
                        </div>
                    ))}
                </div>
            </div>
            <div className="quote-section">
                <blockquote>
                    " 세상이 비록 고통으로 가득하더라도,<br />
                    그것을 극복할 힘도 가득합니다. "<br />
                    <span className="author">헬렌 켈러</span>
                </blockquote>
                <p>우리 모두는 저마다의 투쟁을 하고 있습니다.<br />
                    실패는 낙인으로 찍히고 다른 것은 틀린 것으로 간주되는 사회에서 우리 모두는 하루하루 치열하게 살아가고 있습니다.<br />
                    하티웍스는 바꾸고 싶습니다. 이기주의를 초월하고 불완전함을 포용하는 문화가 사회 전반에 퍼진 사회를 우리는 꿈꿉니다.
                    <strong>그리고 우리는 그것을 우리의 일터에서 시작합니다.</strong><br /><br />

                    하티웍스는 장애인들의 잠재력을 발견하여 세상에 선보이도록 돕는 교육 회사입니다.<br />
                    '장애인의 일자리 문제를 해결한다’는 명확한 목표를 가지고 하티웍스((前)코리안앳유어도어)는 2018년 설립되었습니다.<br />
                    장애인 한국어 강사 양성으로 시작되어 현재 외국어 강사, 개발자, 마음보듬사 등 다양한 직무를 만들었고, 장애인 뿐만 아니라 사회적으로 소외된 이들을 위해 이주 배경 청소년에게 한국어 교육을 하는 등 사회에서 소외된 이들에게 잠재력을 발휘할 수 있는 선순환 모델을 구축해나가고 있습니다.

                    세상이 비록 고통으로 가득하더라도, 그것을 극복할 힘도 가득합니다.<br />
                    하티웍스는 어려움을 겪은 이들이 포용되는 사회를 꿈꾸며 앞으로 나아갈 것입니다.
                </p>
            </div>
            <div className="inclusion-section">
                <div className="items">
                    <img src={exImg4} alt="inclue" className="img" />
                    <div className="content-container">
                        <p className="main-text">
                            포용은 말로 하는 것이 아닙니다.<br />
                            포용은 행동입니다.
                        </p>
                        <div className="box"></div>
                        <blockquote className="author">
                            포용은 자기와 같거나 비슷해서 받아들이는 것이 아닙니다.<br />
                            다른 것을 억지로 같게 만들어서 받아들이는 것도 아닙니다.<br />
                            포용은 다른 상태를 있는 그대로 받아들이는 것입니다.<br /><br />

                            포용에서는 차이에 대한 분명한 인식이 중요합니다.<br />
                            차이를 알면서도 그것 때문에 어떤 차별대우를 하지 않으며 그대로
                            두되 그 자체에서 무언인가가 발현되도록 인내하고 기다리는 것입니다.<br /><br />

                            포용은 말로 하는 것이 아닙니다. 포용은 행동입니다.<br />
                            -'포용의 힘' 정현천
                        </blockquote>
                    </div>
                </div>
            </div>
            <CopDesc></CopDesc>
        </div>
    )
}