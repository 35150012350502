import CopDesc from "../components/CopDesc";
import TopNav from "../components/TopNav";
import mainImg from "../assets/files/스케치1.png"
import exImg1 from "../assets/files/승균.jpg"
import exImg2 from "../assets/files/실습 기홍주.PNG (1).png"
import exImg3 from "../assets/files/이노마드 우노.jpeg"
import exImg4 from "../assets/files/도멘청담.png"
import exImg5 from "../assets/files/노인 고립.jpg"
import exImg6 from "../assets/files/continued.png"
import LabItem from "../components/LabItem";

const labItems = [
    {
        imgSrc: exImg1,
        altText: '시각장애 백엔드 개발자',
        title: '시각장애 백엔드 개발자🔨',
        description: 'JQuery, Java, jsp, Spring, MySQL 등을 학습해 해당 기술을 활용하여 실무 현업에 투입됩니다',
        extraInfo: '*실제로 현재 사용되고 있는 하티웍스 장애인 직원 근태관리앱은 시각장애인 개발자가 개발하였습니다',
    },
    {
        imgSrc: exImg2,
        altText: '시각장애 웹 접근성 교육 전문가',
        title: '시각장애 웹 접근성 교육 전문가👨‍💻',
        description: '장애인이나 고령자가 웹 사이트에서 제공하는 정보를 비장애인과 동등하게 접근하고 이용 할 수 있도록 웹접근성을 교육합니다',
    },
    {
        imgSrc: exImg3,
        altText: '지체장애 탄소 교육 전문가',
        title: '지체장애 탄소 교육 전문가🌳',
        description: '기후변화/탄소중립 문해력 교육을 통해 개인과 학교, 기업이 실질적인 탄소중립을 이행할 수 있도록 돕습니다',
        extraInfo: 'collab. with Enomad (2024.09 예정)',
    },
    {
        imgSrc: exImg4,
        altText: '시각장애 와인 소믈리에',
        title: '시각장애 와인 소믈리에🍷',
        description: '세밀한 후각과 훌륭한 서비스 마인드를 겸비한 와인 소믈리에로 양성하여 최고의 경험을 제공합니다',
        extraInfo: 'collab. with 도멘청담',
    },
    {
        imgSrc: exImg5,
        altText: '시각장애 마음보듬사',
        title: '시각장애 마음보듬사💛',
        description: '온라인 노인상담 전문가로 양성된 하티 실버 마음보듬사는 병원 및 요양시설에 계신 노인의 고립 문제를 해소합니다',
    },
    {
        imgSrc: exImg6,
        altText: 'to be continued',
        title: 'What Next?🍞🐢☕️🎉🍎',
        description: '. . .',
    },
];

export default function Lab({ nowPage, changeFn }) {
    return (
        <div className="lab-main">
            <TopNav nowPage={nowPage} changeFn={changeFn} />
            <img className="main-img" src={mainImg} alt="Main Img"></img>
            <div className="title">
                <h1>하티에서 실험중인 직무들</h1>
            </div>
            <div className="lab-container">
                <div className="lab-content">
                    {labItems.map((item, index) => (
                        <LabItem
                            key={index}
                            imgSrc={item.imgSrc}
                            altText={item.altText}
                            title={item.title}
                            description={item.description}
                            extraInfo={item.extraInfo}
                        />
                    ))}
                </div>
            </div>
            <CopDesc></CopDesc>
        </div>
    )
}